/* ------ Ranking List (Desktop/Mobile) Start ------- */


/* #ranking_02 .rankingList_body  {
  padding: 2rem 0;
} */

#ranking_02 .rankingList_info {
  /* color: #f1931e; */
  text-transform: capitalize;
}

#ranking_02 .section_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: end;
}

#ranking_02 .section_title {
  margin-bottom: .5rem;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  /* font-weight: 600; */
}

/* #ranking_02 .section_title img {
  max-width: 2.5rem;
  margin-right: 0.5rem;
  width: auto;
  height: 2rem;
} */

#ranking_02 .section_details {
  margin-left: .25rem;
  margin-right: .25rem;
  text-decoration: none;
}



/* ---- Ranking List (Mobile) Start ----- */

/* .rankingListlist_table .nav-item button{
   color:#fff;
  } */

  /* #ranking_02 .rankingList_tableTab .rankingList_table_selection {
  background: transparent;
  color: #fff;

} */

/* #ranking_02 .rankingList_tableTab .rankingList_table_selection.active {
  background: #fff;
  color: #000;
} */

/* #ranking_02 .rankingList_table_body { */
  /* padding-top: .5rem;
  padding-right: 1.5rem;
  padding-bottom: .5rem;
  padding-left: 1.5rem; */
/* } */


#ranking_02 .rankingList_table_body .rankingList_table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #fff; */
  flex-direction: row;
}

#ranking_02 .rankingList_table_body .rankingList_table_row .rankingList_user img {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  width: 4rem;
}

#ranking_02 .rankingList_user {
  display: flex;
  flex-direction: row;
  align-items: center;
}




/* ---- Ranking List (Mobile) End ----- */


/* ---- Ranking List (Desktop) Start ----- */

#ranking_02 .rankingList_table_container {
  display: flex;
  width: 100%;
  flex-direction: row;
}

#ranking_02 .rankingList_table_container .rankingList_table {
  width: 50%;
  /* border:1px solid #fff; */
}


#ranking_02 .rankingList_table_container .rankingList_table_header {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* background: radial-gradient(var(--color-button),var(--color-primary)); */
  /* border-bottom: 1px solid #fff; */
}

/* #ranking_02 .rankingList_table_container .rankingList_table_body { */
  /* padding-top: 1rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-left: 2rem; */
/* } */

#ranking_02 .rankingList_table_container .rankingList_table_body .rankingList_table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #fff; */
  flex-direction: row;
  padding: 1rem 0.5rem;


}

#ranking_02 .rankingList_table_container .rankingList_table_body .rankingList_table_row .rankingList_user img {
  /* padding-top: 0.75rem;
  padding-right: 0.5rem;
  padding-bottom: 0.75rem;
  padding-left: 0.5rem; */
  width: 3.5rem;
}




#ranking_02 .rankingList_table_container .rankingList_table:nth-child(1) {
  margin-right: 1.5rem;
}

@media (min-width: 992px){
  #ranking_02  .rankingList_username_col{
    margin-left: 1rem;
  }
}

/* ---- Ranking List (Desktop) End ----- */

/* ------ Ranking List (Desktop/Mobile) End ------- */

.rankingList_tableTab .rankingList_table_selection{
  font-size: 1.25rem;
}