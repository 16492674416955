
.font_h1 {font-size: 2rem;
font-family: 'Noto Sans', sans-serif;
font-weight: lighter;}

.font_h2 {font-size: 1.5rem;
font-family: 'Noto Sans', sans-serif;
font-weight: 600;}

.font_h3 {font-size: 1.17rem;
font-family: 'Noto Sans', sans-serif;
font-weight: normal;}

.font_h4 {font-size: 1rem;
font-family: 'Noto Sans', sans-serif;}

.font_h5 {font-size: 0.83rem;
font-family: 'Noto Sans', sans-serif;
font-weight: normal;}

.font_h6 {font-size: 0.625rem;
font-family: 'Noto Sans', sans-serif;}

.font_body {font-size: 1.6rem;
font-family: 'Noto Sans', sans-serif;}

.font_subtitle {font-size: 1.4rem;
font-family: 'Noto Sans', sans-serif;}

.font_button {font-size: 0.75rem;
font-family: 'Noto Sans', sans-serif;}

.font_caption {font-size: 1.2rem;
font-family: 'Noto Sans', sans-serif;}

.font_overline {font-size: 1rem;
font-family: 'Noto Sans', sans-serif;}

.color_primary {background: #550ea8;
color: #fff;}

.color_secondary {background: #fff;
color: #000;}

.color_button {background: #550ea8;
color: #fff;}

.color_title {background: transparent;
color: #000000;}

.page_color {background: transparent;
color: #000000;}

.color_active {background: transparent;
color: #550ea8;}

.contact_front_d {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;}

.contact_back_d .list-group {border: 1px solid #550ea8;}

.contact_back_d.card >.list-group:last-child {border-radius: 0 0 0 20px;}

.contact_back_d .contact_back_d_header {background: #550ea8;
color: #fff;
border-radius: 20px 0 0 0;}

.contact_back_d .contact_back_d_item {background: #fff;
color: #000;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.announcement_body {color: #000;
border-radius: 5px 5px 5px 5px;}

.announcement_header img {filter: drop-shadow(1px 2px 0px black);}

.announcement_header_title {color: #550ea8;}

.announcement_btnClose {background: #550ea8;
color: #fff;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.header_topBar {background: #fff;
color: #000;
filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 0.1));}

.header_menu {color: #000;}

.header_menu .header_menu_page .header_menu_content.active {background: transparent;
color: #550ea8;
border-bottom: 3px solid #550ea8;}

.banking_dropdown a li,.claim_dropdown li {background: #fff;
color: 8080880;}

.banking_dropdown a.active li,.claim_dropdown a.active li {background: #fff;
color: #550ea8;}

.header_btnLogin {background: #e6e6e6;
color: #000;
border: 1px solid #550ea8;
border-radius: 20px 20px 20px 20px;}

.header_btnRegister {background: #550ea8;
color: #ffffff;
border: 1px solid #550ea8;
border-radius: 20px 20px 20px 20px;}

.header_btnLogout {background: #550ea8;
color: #ffffff;
border-radius: 20px 20px 20px 20px;}

.header_btnRestore {background: #e6e6e6;
color: #000;
border: 1px solid #550ea8;
border-radius: 20px 20px 20px 20px;}

.header_btnBanking {background: #e6e6e6;
color: #000;
border: 1px solid #550ea8;
border-radius: 20px 20px 20px 20px;}

.header_btnPromoClaim {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;}

.header_topBar+.header_banking_info {background: #f9f9f9;
color: #000;
box-shadow: inset 0px 0px 5px 0px rgb(0 0 0 / 20%);}

.header_sideBar_topBar {background: #fff;
color: #000;
filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 0.3));
padding-top: 0;
padding-left: 0;}

.navbar-toggler {background: transparent;
color: #000;}

.header_sideBar .header_sideBar_body {background: #fff;
color: #000;}

.header_sideBar_menu .header_menu_content, .header_sideBar_menu summary.header_menu_content+.sidebar_language_selection p:last-child {background: transparent;
color: #000;
font-weight: 500;
border-bottom: 1px solid #999;
margin: 0 .85rem;}

.header_sideBar_menu .header_menu_content.active {background: transparent;
color: #550ea8;
font-weight: 700;
border-bottom: 1px solid #999;}

.header_banking_info .header_banking_menu_list {color: #999;}

.header_banking_info .header_banking_menu_list .active {color: #550ea8;}

.header_topBar_wrap .header_banking_info .header_balance {background: #e6e6e6;}

.header_topBar_wrap .header_bottom_row .header_balance.coupon_balance {background: #e6e6e6;
outline: 1px solid #550ea8;}

.dropdown_content {background: rgb(0, 0, 0, 0.8);
color: #fff;}

.dropdown_content_container a {filter: none;}

#promoClaimModal label {color: #550ea8;}

#promoClaimModal .filterTab .filterTab_selection.active {background: #550ea8;
color: #fff;}

.footer {background: #f2f2f2;
color: #000;
box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);}

.footer_menu_wrap {border-bottom: 1px dashed #000;}

.footer_payment_method .footer_info_img li:first-of-type img {filter: brightness(1) invert(0);}

.btnCancel {background: transparent;
color: #000;
border: 1px solid #550ea8;
border-radius: 5px 5px 5px 5px;}

.modal .confirm_button {background: #550ea8;
color: #fff;
border-radius: .375rem;
padding: .375rem .75rem;}

.modal .cancel_button {background: transparent;
color: #000;
border: 1px solid #550ea8;
border-radius: .375rem;
padding: .375rem .75rem;}

.tab_pills_color.nav-pills .nav-link:hover {color: #550ea8;}

.footer_help a:hover, .header_banking_menu_list a:hover, .header_menu_right .header_menu_page:hover, .walletBar_selection:hover,.claim_dropdown li:hover {color: #550ea8;}

.header_menu_page .header_text:hover,.header_menu_page:hover {color: #550ea8;}

.banking_dropdown a li:hover,.claim_dropdown a li:hover {background: #fff;
color: #550ea8;}

.scrollToTopButton {background: #550ea8;
color: rgba(255, 255, 255, 1);
border-radius: 50%;
padding: .375rem .75rem;}

.swiper .swiper-pagination-bullet {background: #000;}

.alertMessage_content svg {color: #550ea8;}

.btnCancelPromotion {background: red;
color: #fff;}

.btnSendCode,.register_btnVerify,.forgotUsername_btnVerify,.forgotPassword_btnVerify {background: #550ea8;
color: #fff;
border-radius: 20px;}

button.alertMessage_btnClose {background: #550ea8;
color: #fff;
border-radius: 20px;}

.gameWallet_accordion .gameWallet_accordion_icon {background: #550ea8;
color: #fff;}

#gameWalletModal .game_wallet_dropdown table tbody tr {background: #e6e6e6;
color: #000;}

.header_m .header_logo img {height: 3.8rem;}
