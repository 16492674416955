.section_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: end;
}

.section_title {
    margin-bottom: .5rem;
    /* text-transform: uppercase; */
    display: flex;
    align-items: center;
    /* font-weight: 600; */
}

/* .section_title img {
    max-width: 2.5rem;
    margin-right: 0.5rem;
    width: auto;
    height: 2rem;
} */

.section_details {
    margin-left: .25rem;
    margin-right: .25rem;
    text-decoration: none;
}

.dynamic_template_2 .section_header {
    padding-top: 1.5rem
}

/* ----------- -Include Content- ----------- */

.card_feedback {
    position: relative;
    top: 0;
    padding: 2rem;
    height: 100%;
    width: 100%;
    left: 0;
}


.card_feedback.bgImg_feedback_card {
    position: absolute;
    top: 0;
    padding: 2rem;
    height: 100%;
    width: 100%;
    left: 0;
}



.feedback_info_wrap {
    height: 100%;
}

.feedback_user_wrap {
    display: flex;
    align-items: center;
}

.card_feedback .feedback_user_wrap img {
    height: 3rem;
}

.card_feedback .feedback_user_wrap .feedback_rating img {
    height: 1rem;
}

.feedback_content_wrap,
.feedback_avatar {
    width: 100%;
}

.feedback_userInfo {
    padding-left: .75rem
}

.feedback_username,
.feedback_rating {
    line-height: normal;
}

.section_header.titlePosition_center {
    justify-content: center;
}

.card_feedback.contentPosition_center .feedback_user_wrap {
    justify-content: center;
}

.card_feedback.contentPosition_center .feedback_content_wrap {
    text-align: center;
}

.feedback_subject {
    line-height: normal;
    max-height: 3.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feedback_desc {
    /* height: 8rem;
    overflow-y: auto; */
    max-height: 7.5rem;
    font-size: .85rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feedback_body_m .feedback_subject {
    line-height: normal;
    max-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: -webkit-box;
    word-break: break-word;
    min-height: 2.5rem;
}

.feedback_body_m .feedback_desc {
    /* height: 5rem; */
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: -webkit-box;
    /* font-size: 1rem; */
    max-height: 8rem;
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    /* width: 50%; */
    word-break: break-word;
}

#feedback_container .feedback_body_m .section_header {
    font-size: 1.65rem;

}

#feedback_container .feedback_body_m .section_header.titlePosition_center {

    text-align: center;
}

/* ------------------------------ */
/* .feedback_info_wrap {
    padding: 1.25rem
}

.feedback_username {
    font-size: 1.15rem;
    color: #000;
    font-weight: 600;
}

.feedback_subject {
    font-size: 1.10rem;
    color: #550ea8;
    font-weight: 600;
    padding-bottom: .5rem
}

.feedback_desc {
    font-size: .85rem;
}

.feedback_user_wrap {
    padding-bottom: .85rem
}
 */
