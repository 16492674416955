
.speakerAnnouncement_body {margin: 0.25rem 0;}

.speakerAnnouncement_container {filter: none;}

.speakerAnnouncement_titleContainer {background: transparent;
color: #000;
border-radius: 0;
padding:  0.25rem 1rem;}

.speakerAnnouncement_content {background: transparent;
color: #000;
border-radius: 0 10px 10px 0;
padding:  0.25rem 1rem;}

#Section_howtoregister {background: #fff;
color: #fff;
padding-top: 0;}

#Section_howtoregister .section_title {font-weight: normal;}

.main_game {background: #fff;
padding: 1rem 0 2rem;}

.main_gameSelection {padding-top: 0;}

.main_gameSelection .main_gameSelection_menu {background: transparent;
color: #999999;
font-weight: 600;
border: #ccc;
border-radius: 10px;
padding: 1rem 1rem 0;}

.main_gameSelection .main_gameSelection_menu.active {background: #fff;
color: #550ea8;}

.main_game_container {background: #fff;
color: #000;
border-radius: 10px;}

.main_game_container a {padding: .5rem;}

.main_gameSelection .main_gameSelection_menu .main_gameSelection_image {filter: drop-shadow(2px 2px 3px rgb(0, 0, 0, 0.3));
margin: 0.5rem;}

#Section_LIVE_CASINO .Section_LIVE_CASINO_body {background: #fff;
color: #000;}

#Section_LIVE_CASINO .Section_LIVE_CASINO_body .section_title {font-weight: normal;}

#Section_LIVE_CASINO .card_image_wrap {background: rgba(0, 0, 0, 0);
border-radius: 5px 5px 5px 5px;
filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);}

#Section_LIVE_CASINO .content_container {background: rgba(0, 0, 0, 0);
color: #000;
border-radius: 0px 0px 5px 5px;}

#Section_HOT_GAMES .Section_HOT_GAMES_body {background: #fff;
color: #000;}

#Section_HOT_GAMES .Section_HOT_GAMES_body .section_title {font-weight: normal;}

#Section_HOT_GAMES .card_image_wrap {background: rgba(0, 0, 0, 0);
border-radius: 5px 5px 5px 5px;
filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);}

#Section_HOT_GAMES .content_container {background: rgba(0, 0, 0, 0);
color: #000;
border-radius: 0px 0px 5px 5px;}

#Section_partners .templateSliderContainer {background: #f2f2f2;
color: #000;
border-radius: 10px;
padding: 0 1rem;}

#Section_partners .Section_partners_body {background: #fff;
color: #000;}

#Section_partners .Section_partners_body .section_title {font-weight: normal;}

#Section_partners .card_container {background: #fff;
border-radius: 10px;
margin: 1rem .5rem;
padding: 0.5rem 1rem;}

#Section_partners .card_image_wrap {background: transparent;
border-radius: 10px 10px 10px 10px;
filter: none;}

#Section_partners .content_container {background: rgba(0, 0, 0, 0);
color: #000;
border-radius: 0px 0px 5px 5px;}

.rankingList_body {background: #00013e;
color: #fff;
padding: 1.5rem 0 1.5rem;}

.rankingList_body .section_title {font-weight: normal;}

.rankingList_table {background: #fff;
border-radius: 15px 15px 15px 15px;
filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

.rankingList_table_header {background: linear-gradient(to top, #00013e, #0000f8);
color: #f6f40d;
font-weight: normal;
border-radius: 10px 10px 10px 10px;
margin: 0;
padding: 1rem;}

.rankingList_table_container .rankingList_table_header+.rankingList_table_body {padding: 0;}

.rankingList_table_row {background: #fff;
color: #000;
border-bottom: 1px solid #ccc;
margin-left: 1rem;
margin-right: 1rem;}

.rankingList_username {font-weight: bold;}

.rankingList_info {color: #f90c00;}

.rankingList_amount {font-weight: bold;}

.rankingList_m_table .rankingList_tableTab {background: #0000f8;
border: 0;
border-radius: 10px 10px 10px 10px;
padding: 0.65rem;}

.rankingList_tableTab .rankingList_table_selection {background: transparent;
color: #999;
border-radius: 0;
border-right: 1px solid #999;
padding: .75rem 1rem;}

.rankingList_tableTab .rankingList_table_selection.active {background: transparent;
color: #fff;
border: 0;
border-radius: 0;
border-right: 1px solid #999;}

.rankingList_m_table {background: #fff;
border-radius: 10px;}

.rankingList_m_table .rankingList_table {background: #fff;
border-radius: 10px 10px 10px 10px;
filter: none;
padding: 0;}

.rankingList_m_table .rankingList_table_body {margin-top: .5rem;}

.rankingList_m_table .rankingList_table_row {background: #fff;
border-radius: 0;
padding: 1rem;}

.feedback_body {background: #fff;
padding-top: 3.5rem;
padding-bottom: 1.5rem;}

#feedback_container .section_header {font-size: 2.5rem;
font-weight: bold;
margin-bottom: 1rem;}

#feedback_container .card_container {filter: drop-shadow(2px 3px 4px rgb(0, 0, 0, .5));}

.feedback_info_wrap {padding: 1.75rem 1.5rem 1.25rem;}

.feedback_user_wrap {padding-bottom: .85rem;}

.feedback_username {color: #000;
font-size: 1.15rem;
font-weight: 600;}

.feedback_subject {color: #550ea8;
font-size: 1.10rem;
font-weight: bold;
margin-bottom: .5rem;}

.feedback_desc {font-size: .85rem;}

.contactUs_body {background: #fff;
color: #000;}

#contactUs_title.pageTitle {background: #fff;
color: #000;
padding-top: 0;
padding-bottom: 2.5rem;}

.contact_card.card {background: transparent;
border-radius: 0.8rem;
box-shadow: inset 0 0 5px 0px rgba(0,0,0,.5);
border-top: 0;
border-bottom: 0;
border-left: 0;
border-right: 0;
margin: 10px;
padding: 1rem;}

.contact_container .contact_container_row {border-radius: 10px;
margin: 4rem;
padding: 1rem;}

#contactUs_title_m {background: #f2f2f2;
color: #000;
padding: 1rem 1.25rem 1rem;}

.contact_container_m .contact_card.card {background: transparent;
border-radius: 0;
box-shadow: none;
border-top: 0;
border-bottom: 1px solid #808080;
border-left: 0;
border-right: 0;
margin: 10px 10px 0 10px;
padding: 0 1rem;}

.contact_container_m .contact_container_row {border: 0;
border-radius: 0;
box-shadow: none;
margin: 0;
padding: 0;}

#promotion_title {background: #f2f2f2;
color: #000;}

#Section_promoContainer .Section_promoContainer_body {background: #fff;}

#Section_promoContainer .card_container {border-bottom: 1px dashed #000;
padding: 0.8rem 1.5rem;}

#Section_promoContainer .card_image_wrap {border-radius: 0;
filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);}

#Section_promoContainer .content_container {background: rgba(0, 0, 0, 0);
color: #000;}

#Section_promoContainer .card_content_title {color: #550ea8;}

#Section_promoContainer .button_action1 {background: #e6e6e6;
color: #000;
border: 1px solid #550ea8;
border-radius: 20px 20px 20px 20px;}

#Section_promoContainer .button_action2 {background: #550ea8;
color: rgba(255, 255, 255, 1);
border: 0;
border-radius: 20px 20px 20px 20px;}

#Section_promoContainer .filterTab {background: #f3f4f6;
border-radius: 25px 25px 25px 25px;
margin-top: 2rem;
padding: 0.5rem;}

#Section_promoContainer .filterTab .filterTab_selection {border-radius: 20px 20px 20px 20px;
padding: 0.5rem 1rem;}

#Section_promoContainer .filterTab .filterTab_selection.active {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;}

#Section_promoContainer .promotionCode_part {border: 1px solid #550ea8;
border-radius: 20px 20px 20px 20px;}

#Section_promoContainer .promotionCode_part label {background: #e6e6e6;
color: #000;
border-radius: 20px 0 0 20px;}

#Section_promoContainer .promotionCode_part .promotionCode_content {background: #550ea8;
color: #fff;
border-radius: 0 20px 20px 0;}

#Section_promoContainer .promotionTag_part label {background: #c2aedf;
color: #550ea8;
border-radius: 5px 5px 5px 5px;
margin-right: .5rem;
padding: .25rem .5rem;}

#Section_promoContainer .Section_promoContainer_body {background: #fff;}

#Section_promoContainer .card_container {border-bottom: 1px dashed #000;
padding: 0.8rem 1.5rem;}

#Section_promoContainer .card_image_wrap {border-radius: 0;
filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);}

#Section_promoContainer .content_container {background: rgba(0, 0, 0, 0);
color: #000;}

#Section_promoContainer .card_content_title {color: #550ea8;}

#Section_promoContainer .button_action1 {background: #e6e6e6;
color: #000;
border: 1px solid #550ea8;
border-radius: 20px 20px 20px 20px;}

#Section_promoContainer .button_action2 {background: #550ea8;
color: rgba(255, 255, 255, 1);
border: 0;
border-radius: 20px 20px 20px 20px;}

#Section_promoContainer .filterTab {background: #f3f4f6;
border-radius: 25px 25px 25px 25px;
margin-top: 2rem;
padding: 0.5rem;}

#Section_promoContainer .filterTab .filterTab_selection {border-radius: 20px 20px 20px 20px;
padding: 0.5rem 1rem;}

#Section_promoContainer .filterTab .filterTab_selection.active {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;}

#Section_promoContainer .promotionCode_part {border: 1px solid #550ea8;
border-radius: 20px 20px 20px 20px;}

#Section_promoContainer .promotionCode_part label {background: #e6e6e6;
color: #000;
border-radius: 20px 0 0 20px;}

#Section_promoContainer .promotionCode_part .promotionCode_content {background: #550ea8;
color: #fff;
border-radius: 0 20px 20px 0;}

#Section_promoContainer .promotionTag_part label {background: #c2aedf;
color: #550ea8;
border-radius: 5px 5px 5px 5px;
margin-right: .5rem;
padding: .25rem .5rem;}

.help_body {background: #fff;
padding: 0 0 3rem;}

#help_title {background: #f2f2f2;
color: #000;}

.help_accordion {padding: 1.5rem;}

.help_accordion .accordion-item .accordion-button {background: #f3f4f6;
color: #000;
border: 1px solid #550ea8;
border-radius: 20px;}

.help_accordion .accordion-item:last-of-type .accordion-button.collapsed {background: #f2f2f2;
color: #000;
border-radius: 20px;}

.help_accordion .accordion-item .accordion-button:not(.collapsed) {background: #550ea8;
color: rgba(255, 255, 255, 1);
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.help_accordion .accordion-item .accordion-collapse {border: 1px solid #f2f2f2;
border-radius: 0 0 5px 5px;
filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, .7));
border-top: 0;}

.help_accordion .accordion-item .accordion-body {background: #fff;
color: #000;
border-radius: 0;}

.login_body {background: #fff;
color: #000;}

#login_title_d {background: #f2f2f2;
color: #550ea8;
font-weight: bold;}

#login_title_m {background: transparent;
color: #550ea8;
font-weight: 700;}

.login_container {background: #f2f2f2;
color: #000;
border-radius: 15px 15px 15px 15px;
filter: drop-shadow(rgb(155, 155, 155) 0px 1px 3px);}

.login_form .form-label {color: #000;
font-size: 1.4rem;}

.login_form .form-control {font-size: 1.3rem;
border-radius: 20px 20px 20px 20px;}

.login_btnLogin {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.register_body {background: #fff;
color: #000;}

#register_title_d {background: #f2f2f2;
color: #550ea8;
font-weight: bold;}

#register_title_m {background: transparent;
color: #550ea8;
font-weight: 700;}

.register_container {background: #f2f2f2;
color: #000;
border-radius: 15px 15px 15px 15px;
filter: drop-shadow(rgb(155, 155, 155) 0px 1px 3px);}

.register_stepper button {background: #e6e6e6;
color: #000;}

.register_stepper .register_stepContainer.active button {background: rgba(57, 181, 74, 1);
color: rgba(255,255,255, 1);}

.register_stepContainer:not(:first-child):before {background: rgb(224, 224, 224);}

.register_stepContainer.active:not(:first-child)::before {background: green;}

.register_form .form-label {color: #000;
font-size: 1.4rem;}

.register_form .form-control,.register_form .form-select {font-size: 1.3rem;
border-radius: 20px 20px 20px 20px;}

.register_btnNext {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.register_btnBack {background: #e6e6e6;
color: #000;
border: 1px solid #550ea8;
border-radius: 20px 20px 20px 20px;}

.register_btnDepositNow {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.register_btnSend {background: #550ea8;
color: #fff;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.forgotPassword_body {background: #fff;
color: #000;}

#forgotPassword_title_d {background: #f2f2f2;
color: #550ea8;
font-weight: bold;}

#forgotPassword_title_m {background: transparent;
color: #550ea8;
font-weight: 700;}

.forgotPassword_container {background: #f2f2f2;
color: #000;
border-radius: 15px 15px 15px 15px;
filter: drop-shadow(rgb(155, 155, 155) 0px 1px 3px);}

.forgotPassword_form .form-label {color: #000;}

.forgotPassword_form .form-control,.forgotPassword_form .form-select {border-radius: 20px 20px 20px 20px;}

.forgotPassword_btnNext {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.forgotPassword_btnBack {background: #e6e6e6;
color: #fff;
border: 1px solid #550ea8;
border-radius: 20px 20px 20px 20px;}

.forgotPassword_btnSubmit {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.forgotPassword_btnSend {background: #550ea8;
color: #fff;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.forgotUsername_body {background: #fff;
color: #000;}

#forgotUsername_title_d {background: #f2f2f2;
color: #550ea8;
font-weight: bold;}

#forgotUsername_title_m {background: transparent;
color: #550ea8;
font-weight: 700;}

.forgotUsername_container {background: #f2f2f2;
color: #000;
border-radius: 15px 15px 15px 15px;
filter: drop-shadow(rgb(155, 155, 155) 0px 1px 3px);}

.forgotUsername_form .form-label {color: #000;}

.forgotUsername_form .form-control,.forgotUsername_form .form-select {border-radius: 20px 20px 20px 20px;}

.forgotUsername_btnSubmit {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.forgotUsername_btnSend {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.myProfile_body {background: #fff;
color: #000;}

#myProfile_title {background: transparent;
color: #550ea8;}

.myProfile_mySetting_row {background: #f2f2f2;
color: #000;
padding: 1.35rem;}

.myProfile_myAccount_row {background: #f2f2f2;
color: #000;}

.myProfile_myAccount_row svg {color: #550ea8;}

.slots_body {background: #fff;}

#slot_title {background: transparent;
color: #000;
font-weight: bold;}

.slots_body .title_container a+div select {border-radius: 20px;}

.gameProviderTab_container {background: #f3f4f6;
border-radius: 10px 10px 10px 10px;}

.gameProviderTab .gameProviderTab_selection {background: transparent;
border-radius: 10px 10px 10px 10px;
border-top: 1px solid #550ea8;
border-bottom: 1px solid #550ea8;
border-left: 1px solid #550ea8;
border-right: 1px solid #550ea8;}

.gameProviderTab .gameProviderTab_selection.active {background: rgba(255, 255, 255, 1);
color: rgb(0, 0, 0, 1);}

.gameProviderTab_container button {background: transparent;
color: #550ea8;}

.slotTypeTab {background: #f3f4f6;
border-radius: 20px 20px 20px 20px;}

.slotTypeTab .slotTypeTab_selection.active {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;}

#slotGameContainer .button_action1 {background: #550ea8;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

#slotGameContainer .card_image_wrap {border-radius: 5px;
filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);}

#slotGameContainer .content_container {background: rgba(255, 255, 255, 0);
color: #fff;
border-radius: 0px 0px 5px 5px;}

.mslot_container {margin: 4rem 10rem;}

.mslot_container_m {margin: 0 5rem 1rem;}

.mslot_container .mslot_wrap {background: #f2f2f2;
color: #000;
border-radius: 0.5rem;
box-shadow: 2px 0px 6px 0px rgba(155, 155, 155, 1);;
padding: 3.5rem 2rem;}

.mslot_container_m .mslot_wrap {background: transparent;
color: #000;
border-radius: 0;
box-shadow: none;
padding: 1.5rem;}

.mslot_userInfo label {color: #000;;}

#mslotGameContainer .mslotGameContainer_body {background: #fff;
padding-top: 1.5rem;}

#Section_1675926801290 .card_image_wrap {filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);}

#mslotGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #000;}

#casinoGameContainer .casinoGameContainer_body {background: #fff;
padding-top: 1.5rem;}

#casinoGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #000;
font-weight: bold;}

#sportGameContainer .sportGameContainer_body {background: #fff;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;
padding-top: 1.5rem;}

#sportGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #000;
font-weight: bold;}

#esportGameContainer .sportGameContainer_body {background: #fff;
padding-top: 1.5rem;}

#esportGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #000;
font-weight: bold;}

#fishingGameContainer .fishingGameContainer_body {background: #fff;
padding-top: 1.5rem;}

#fishingGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #000;
font-weight: bold;}

#lotteryGameContainer .lotteryGameContainer_body {background: #fff;
padding-top: 1.5rem;}

#lotteryGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #000;
font-weight: bold;}

#boardGameContainer .boardGameContainer_body {background: #fff;
padding-top: 1.5rem;}

#boardGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #000;
font-weight: bold;}

#othersContainer .othersContainer_body {background: #fff;
padding-top: 1.5rem;}

#othersContainer .content_container {background: rgba(231, 231, 231, 0);
color: #000;
font-weight: bold;}

.wallet_body {background: #fff;}

.wallet_body .pageTitle {background: transparent;
color: #000;}

.wallet_container_title {background: #550ea8;
color: #fff;}

.walletBar {background: #fff;
color: #000;
filter: drop-shadow(2px 3px 3px rgb(155,155,155,1));}

.walletBar_userInfo {color: #000;}

.walletBar_userVerification,.walletBar_point,.walletBar_walletUserQrInfo {background: #f2f2f2;}

.walletBar_selection {background: #e6e6e6;
color: #000;
border-radius: 25px 25px 25px 25px;}

.walletBar_selection.active {background: #550ea8;
color: #fff;
border-radius: 25px 25px 25px 25px;
filter: none;}

.wallet_container {border-radius: 10px 10px 10px 10px;
box-shadow: 2px 2px 5px 3px rgba(155, 155, 155, .8);}

.wallet_container_wrap {background: #fff;
color: #000;}

.wallet_depositMethod img+div,.deposit_channel_row img+div {color: #a6a8ab;}

.wallet_depositMethod img+div.active,.deposit_channel_row img+div.active {color: #550ea8;}

.wallet_btnSubmit,.wallet_gameWallet_btnAllIn,.wallet_gameWallet_btnRefresh,.wallet_btnSubmit.btnSearch {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.wallet_container .form-select,.wallet_container .form-control:not(.wallet_container .form-control[type='file'],.wallet_container .form-control:disabled) {border-radius: 20px 20px 20px 20px;}

.wallet_container .form-control:disabled {background: #f2f2f2;
color: rgba(0, 0, 0, 1);
border-radius: 20px 20px 20px 20px;}

.wallet_reminder_container {background: #f2f2f2;
color: #000;}

.wallet_reminder_title {color: red;}

.table.wallet_history_table thead,.myAccount_notification_header,.myAccount_bankingDetails_tableHeader {background: #550ea8;
color: rgba(255,255,255, 1);
border-top: 0px solid transparent;
border-bottom: 0px solid transparent;
border-left: 0px solid transparent;
border-right: 0px solid transparent;}

.table.wallet_history_table tbody,.myAccount_notification_title_row,.myAccount_bankingDetails_table {background: #f2f2f2;
color: rgba(0,0,0,1);
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.wallet_btnAmount button {background: #fff;
color: #000;
border-radius: 20px 20px 20px 20px;}

.wallet_btnAmount button.active {background: #550ea8;
color: #fff;}

.myAccount_notification_content {background: #fff;
color: #000;}

.myAccount_changePasswordTab .myAccount_changePasswordTab_selection.active {background: red;
color: #fff;}

.myAccount_userInfo_profile label {color: #550ea8;}

.transactionTypeTab {background: #f2f2f2;
border-radius: 20px 20px 20px 20px;
padding: 0.5rem;}

.transactionTypeTab .transactionType_selection {background: transparent;
color: #000;
border-radius: 20px 20px 20px 20px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;
margin-right: 1rem;
padding: 0.5rem 1rem;}

.transactionTypeTab .transactionType_selection.active {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;}

.wallet_body_container_m {padding: 0;}

.wallet_container_m {color: #000;
border: 0;
filter: none;
box-shadow: none;}

.wallet_container_m .wallet_container_wrap {background: transparent;}

.wallet_container_m .wallet_reminder_container {background: transparent;}

.walletBar_btnLogout {background: red;
color: #ffffff;
border-radius: 20px 20px 20px 20px;
border-top: 0px none ;
border-bottom: 0px none ;
border-left: 0px none ;
border-right: 0px none ;
padding: .75rem;}

.depositBankImg img {border-radius: .375rem;
border-top: 1px solid #550ea8 ;
border-bottom: 1px solid #550ea8;
border-left: 1px solid #550ea8;
border-right: 1px solid #550ea8;}

.friendList_group_title {background: #e6e6e6;
color: #000;}

.friendList_group_item_container {color: #000;}

.friendDetail_info_phone, .friendDetail_info_status, .friendList_info_phone, .friendList_info_status {color: #4d4d4d;}

.friendRequest_tab {background: #e6e6e6;
color: #000;}

.friendRequest_tab .friendRequestTab_selection.active {background: #550ea8;
color: #fff;}

.friendDetail_group_item, .searchFriend_container {background: #f2f2f2;
color: #000;
border: 1px solid #b3b3b3;}

.friendRequest_tab .friendRequestTab_selection:hover {color: #550ea8;}

.friendRequest_tab .friendRequestTab_selection.active:hover {color: #fff;}

.wallet_container_m .realNameVerification_title {border-left: 3px solid #550ea8;}

.vip_body {background: #000;}

.vip_container {margin: 0 20rem;}

.vip_container_m {margin: 0;}

.vipTab {margin: 2rem 0;}

.vipTab .vipTab_selection {color: #fff;}

.vipTab .vipTab_selection.active {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;}

#vip_title {background: #f2f2f2;
color: #000;}

vip_scrollContent .section {background: #17002a;}

.coupon_body {background: #fff;
padding: 0 0 3rem;}

#coupon_title {background: #f2f2f2;
color: #000;}

#coupon .filterTab {background: #f2f2f2;
border-radius: 20px 20px 20px 20px;
margin-top: 2rem;
padding: 0.5rem;}

#coupon .filterTab .filterTab_selection {padding: 0.5rem 1rem;}

#coupon .filterTab .filterTab_selection.active {background: #550ea8;
color: #fff;
border-radius: 20px 20px 20px 20px;}

.card_coupon {filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.3));;}

.coupon_content_wrapper {color: #fff;}

.coupon_body_container {color: rgba(0,0,0,1);}

.button_couponClaim {border-radius: 20px 20px 20px 20px;
padding: 0.5rem;}

.coupon_expireTime {color: red;
margin-bottom: 1rem;}

.coupon_tnc {color: #0d6efd;}

.button_claimCoupon {border-radius: 20px;
margin-top: .75rem;
padding: .5rem;}


.App,.page{
    background:transparent
}




